<template>
  <div>
    <v-card flat :loading="loading">
        <v-row class="pa-3" align="center">
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" class="text-right mb-0 pb-0">
            <v-chip style="height: 40px" outlined small class="pr-1 text-center">
              <v-menu offset-y :disabled="selectedContainers.length == 0">
                <template v-slot:activator="{ on }">
                  <v-chip small class="primaryText--text mx-1" :color="selectedContainers.length > 0 ? 'blue' : 'grey'"
                    v-on="on" style="border: none">
                    Actions<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <!-- <v-list-item style="height: 35px">
                    <v-list-item-action>
                      <v-icon color="grey">post_add</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Create New Booking </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->

                  <v-list-item style="height: 35px" @click="assignToBookingModal = true,getUpcomingBooking()">
                    <v-list-item-action>
                      <v-icon color="grey">logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Add to Existing Booking </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-chip small class="mr-1 pr-3">TOTAL:
                <span style="padding-right: 5px; margin-left: 5px">
                  {{ displayData.length }}</span>
              </v-chip>
              <v-text-field placeholder="Search" class="mb-1" hide-details rounded clearable dense style="width: 300px"
                v-model="search"></v-text-field>
            </v-chip>
            <v-row class="my-3" :key="updateFilterValue">
              <div :key="updateFilterValue">
                <v-chip color="red" class="mx-1" small @click="resetFilters()" v-if="filteredValues.length > 0">
                  <v-icon small left>filter_alt_off</v-icon> Clear Filters
                </v-chip>
                <v-chip class="mx-1" close small @click:close="
                  addToFilter(filterValue.value, filterValue.field)
                  " v-for="(filterValue, index) in filteredValues" :key="index">{{ filterValue.value }}</v-chip>
              </div>
            </v-row>
          </v-col>
        </v-row>
      <v-card-text>
        <div :style="{ width: tableWidth }" v-if="displayData.length > 0">
          <table :width="'100%'">
            <tr class="mx-0 px-0">
              <td
                class="text-center mx-0 px-0"
                style="
                  cursor: grab;
                  border-right: 1px solid grey;
                  min-height: 20px;
                  min-width: 20px;
                "
                v-for="(header, index) in headers"
                :key="index"
                :width="header.width ?? '150px'"
              >
                <div>
                  <v-row align="center" justify="center" class="py-2">
                    <v-checkbox v-if="header.value == 'actions'" :indeterminate="selectedContainers.length > 0 &&
                          selectedContainers.length < filteredResults.length
                          " hide-details v-model="selectAllContainers" dense class="mx-0 px-0 mb-2"></v-checkbox>
                    <b style="font-size: 12px">{{ header.text }}</b>
                    <v-btn
                      v-if="!header.disableSort"
                      class="ml-1"
                      icon
                      x-small
                      @click="changeSortBy(header.value)"
                    >
                      <v-icon v-if="sortBy != header.value" small color="grey"
                        >arrow_upward</v-icon
                      >
                      <v-icon v-else-if="sortDesc" small>arrow_downward</v-icon>
                      <v-icon v-else small>arrow_upward</v-icon>
                    </v-btn>
                    <v-badge v-if="!header.disableFilter" :content="filters[header.value]
                            ? filters[header.value].length
                            : false
                          " :value="filters[header.value] &&
    filters[header.value].length > 0
    " color="primary" offset-y="10">
                          <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" x-small color="grey" icon><v-icon
                                  small>filter_alt</v-icon></v-btn>
                            </template>
                            <v-card style="
                                background: var(--v-modal-base) !important;
                                border-radius: 20px;
                              ">
                              <v-card-text>
                                <v-list dense style="
                                    background: var(--v-modal-base) !important;
                                    border-radius: 20px;
                                  ">
                                  <v-text-field hide-details rounded dense outlined clearable prepend-inner-icon="search"
                                    placeholder="Search" autofocus v-model="searchHeaders[header.value]"
                                    @input="updateSearchKey()"></v-text-field>
                                  <v-list dense style="
                                      max-height: 50vh;
                                      overflow-y: auto;
                                      background: var(
                                        --v-modal-base
                                      ) !important;
                                    " :key="searchKey">
                                    <v-list-item v-for="(item, index) in headerOptions(
                                      header.value
                                    )" :key="index">
                                      <v-list-item-action class="pr-0">
                                        <v-checkbox v-model="filters[header.value]" :value="item.value" hide-details dense
                                          @change="calculateFilteredResults()"></v-checkbox>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.name }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="headerOptions(header.value).length == 0
                                      ">
                                      <v-list-item-content class="text-center">
                                        <span style="font-size: 12px">No results found</span>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </v-badge>
                  </v-row>
                </div>
              </td>
            </tr>
          </table>
          <v-divider></v-divider>
          <div
            class="mt-1 pt-1 px-1"
            :style="{ width: '100%' }"
            v-if="displayData.length > 0"
          >
            <v-virtual-scroll
              v-if="displayData.length > 0"
              :bench="10"
              :items="displayData"
              :height="calculatedHeight"
              :width="'100%'"
              item-height="35"
              class="px-1"
            >
              <template v-slot:default="{ item }">
                <div
                  class="text-center my-0 py-0"
                  :style="{
                    display: 'flex',
                    'flex-direction': 'row',
                    width: '100%',
                    'background-color':
                      selectedContainerItem &&
                      selectedContainerItem.id == item.id
                        ? 'rgba(12, 97, 24, 0.6)'
                        : 'transparent',
                  }"
                  @contextmenu="viewItem($event, item)"
                >
                  <td
                    v-for="(header, index) in headers"
                    :key="index"
                    :style="{ width: header.width ?? '150px' }"
                    class="text-center mt-0 mb-0 pb-0"
                  >
                    <div v-if="header.value == 'actions'">
                      <v-row align="center" justify="center" class="py-1">
                        <v-checkbox hide-details dense :value="selectedContainers
                              .map((x) => x.id)
                              .includes(item.id)
                            " @change="selectContainer(item)" class="mb-2 mx-0 px-0"></v-checkbox>
                        <v-btn icon @click="viewBooking(item)" small>
                          <v-icon small>launch</v-icon>
                        </v-btn>
                      </v-row>
                    </div>
                    <div v-if="header.value == 'status'" class="text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :color="statusColor(item.status)"
                            small
                            icon
                            v-on="on"
                            @click="addToFilter(item.status, 'status')"
                          >
                            <v-icon>{{ statusIcon(item.status) }}</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">{{
                          $Format.capitalizeFirstLetter(item.status)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'userFullName'"
                      class="text-center"
                    >
                      <v-tooltip top v-if="item.user">
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            style="cursor: pointer"
                            size="24"
                            class="ml-1"
                            v-on="on"
                            :color="item.user.avatar ? 'white' : 'secondary'"
                            @click="
                              addToFilter(item.userFullName, 'userFullName')
                            "
                          >
                            <v-img
                              v-if="item.user.avatar"
                              contain
                              :src="item.user.avatar"
                            ></v-img>
                            <h4 v-else>{{ item.user.firstname.charAt(0) }}</h4>
                          </v-avatar>
                        </template>
                        <span style="font-size: 12px"
                          >{{ item.user.firstname }}
                          {{ item.user.surname }}</span
                        >
                      </v-tooltip>
                    </div>

                    <!-- <div v-else-if="header.value == 'type'" class="text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :color="bookingTypeColor(item.type)"
                            small
                            icon
                            v-on="on"
                            @click="addToFilter(item.type, 'type')"
                          >
                            <v-icon>{{ bookingTypeIcon(item.type) }}</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">{{
                          $Format.capitalizeFirstLetter(item.type)
                        }}</span>
                      </v-tooltip>
                    </div> -->
                    <div
                      v-else-if="header.value == 'vesselVoyage'"
                      class="text-left"
                    >
                      <v-chip
                        v-if="item.vesselVoyage"
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item.vesselVoyage, 'vesselVoyage')"
                        style="border: none; font-size: 11px"
                        ><span>{{ item.vesselVoyage }}</span>
                      </v-chip>
                    </div>
                    <div
                      v-else-if="header.value == 'portOfLoadValue'"
                      class="text-center"
                    >
                      <v-tooltip top v-if="item.portOfLoadValue">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            outlined
                            style="border: none; font-size: 11px"
                            v-on="on"
                            small
                            @click="
                              addToFilter(
                                item.portOfLoadValue,
                                'portOfLoadValue'
                              )
                            "
                          >
                            <v-avatar size="20" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ item.portOfLoadValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
                          item.portOfLoadCity
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'finalDestinationValue'"
                      class="text-center"
                    >
                      <v-tooltip top v-if="item.finalDestinationValue">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            outlined
                            style="border: none; font-size: 11px"
                            v-on="on"
                            small
                            @click="
                              addToFilter(
                                item.finalDestinationValue,
                                'finalDestinationValue'
                              )
                            "
                          >
                            <v-avatar size="20" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ item.finalDestinationValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
                          item.finalDestinationCity
                        }}</span>
                      </v-tooltip>
                    </div>

                    <div
                      v-else-if="header.value == 'consigneeName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.booking.consignee">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.booking.consignee"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.consigneeName, 'consigneeName')
                            "
                            v-on="on"
                            style="border: none"
                          >
                            <v-avatar
                              size="24"
                              :color="
                                item.booking.consignee && item.booking.consignee.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                style=""
                                v-if="item.booking.consignee.logo"
                                :src="item.booking.consignee.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.consigneeName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.consigneeName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.consignee.name }}</span>
                      </v-tooltip>
                    </div>

                    <div
                      v-else-if="header.value == 'customerName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.booking.customer">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.booking.customer"
                            small
                            class="primaryText--text"
                            v-on="on"
                            outlined
                            @click="
                              addToFilter(item.customerName, 'customerName')
                            "
                            style="border: none"
                          >
                            <!-- <v-icon
                              class="mr-2"
                              small
                              left
                              v-if="item.clientStatus"
                              :color="getClientStatusColor(item.clientStatus)"
                              >fiber_manual_record</v-icon
                            > -->
                            <v-avatar
                              size="20"
                              :color="
                                item.booking.customer && item.booking.customer.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.booking.customer.logo"
                                :src="item.booking.customer.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.customerName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.customerName }}
                          </v-chip>
                        </template>
                        <span
                          >{{ item.booking.customer.name }}
                          <span v-if="item.clientStatus">
                            - Status: {{ item.clientStatus }}</span
                          ></span
                        >
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'shipperName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.booking.shipper">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.booking.shipper"
                            small
                            class="primaryText--text"
                            v-on="on"
                            outlined
                            @click="
                              addToFilter(item.shipperName, 'shipperName')
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="20"
                              :color="
                                item.booking.shipper && item.booking.shipper.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.booking.shipper.logo"
                                :src="item.booking.shipper.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.shipperName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.shipperName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.shipper.name }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'onBehalfShipperName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.booking.onBehalfShipper">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.booking.onBehalfShipper"
                            small
                            class="primaryText--text"
                            v-on="on"
                            outlined
                            @click="
                              addToFilter(
                                item.onBehalfShipperName,
                                'onBehalfShipperName'
                              )
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="20"
                              :color="
                                item.booking.onBehalfShipper &&
                                item.booking.onBehalfShipper.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.booking.onBehalfShipper.logo"
                                :src="item.booking.onBehalfShipper.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.onBehalfShipperName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.onBehalfShipperName }}
                          </v-chip>
                        </template>
                        <span>{{ item.booking.onBehalfShipper.name }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'shippingLineName'"
                      class="text-left"
                    >
                      <v-chip
                        v-if="item.booking.shippingLine"
                        small
                        class="primaryText--text"
                        outlined
                        @click="
                          addToFilter(item.shippingLineName, 'shippingLineName')
                        "
                        style="border: none"
                      >
                        <v-avatar
                          size="24"
                          :color="
                            item.booking.shippingLine && item.booking.shippingLine.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            v-if="item.booking.shippingLine.logo"
                            :src="item.booking.shippingLine.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.shippingLineName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.shippingLineName }}
                      </v-chip>
                    </div>
                    <div v-else-if="header.value == 'etd'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>
                  
                    <div v-else-if="header.value == 'eta'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>

                    <div v-else-if="header.value == 'movementType'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.movementType, header.value)
                            "
                            style="border: none"
                          >
                            <v-avatar
                              :color="
                                item.movementType == 'EXPORT'
                                  ? 'deep-orange'
                                  : 'blue'
                              "
                              left
                            >
                              <h4 style="color: white">
                                {{ item.movementType.charAt(0) }}
                              </h4>
                            </v-avatar>
                            {{ item.orderNo }}
                          </v-chip>
                        </template>
                        <span
                          >{{
                            $Format.capitalizeFirstLetter(item.movementType)
                          }}
                          Booking</span
                        >
                      </v-tooltip>
                    </div>

                    <div v-else>
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                  </td>
                </div>

                <v-divider class="my-0 py-0"></v-divider>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
        <div class="container" v-if="!loading && displayData.length == 0">
          <h3 style="color: grey">No Results Found</h3>
        </div>
        <div class="container" v-else-if="loading && displayData.length == 0">
          <valhalla-loading height="500px" />
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="assignToBookingModal" width="600px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card :loading="loadingCustomerBooking">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Select a Booking
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="assignToBookingModal = false">X</v-btn>
        </v-toolbar>
        <!-- <v-card-subtitle v-if="container"><v-icon class="mr-1 pb-1" color="grey"> widgets</v-icon>
          {{ container.ctoNo }}</v-card-subtitle> -->
         
        <v-card-text>
            <v-autocomplete label="Customer" @change="getUpcomingBooking()" :loading="loadingOrganisations" outlined dense v-model="customerId" :items="organisations" :filter="filterObject" item-text="name" item-value="id">
                <template v-slot:item="data">
                    <v-list-item-action>
                        <v-avatar size="32" :color="data.item.logo?'white': 'secondary'">
                        <v-img v-if="data.item.logo" :src="data.item.logo" contain></v-img>
                            <h3 v-else>{{ data.item.name.charAt(0) }}</h3>
                        </v-avatar>
                    </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="data.item.alias">
                  {{ data.item.alias }}</v-list-item-subtitle>
              </v-list-item-content>
            </template></v-autocomplete>
          <v-text-field placeholder="Search" v-model="searchBooking" outlined dense clearable hide-details></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item v-if="filterAvailableBookings.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No bookings found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="booking in filterAvailableBookings" :key="booking.id" @click="moveContainerConfirm(booking)">
              <v-list-item-content>
                <v-list-item-title>{{ booking.orderNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="booking.shipper">
                  <v-icon class="mr-1 pb-1" color="grey" small>groups</v-icon>
                  {{ booking.shipper.name }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="booking.consignee">
                  <v-icon class="mr-1 pb-1" color="grey" small>call_received</v-icon>
                  {{ booking.consignee.name }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="booking.vessel">
                  <v-icon class="mr-1 pb-1" color="grey" small>directions_boat</v-icon>
                  {{ booking.vessel.name }}
                  {{ booking.mainCarriageConveyanceNumber }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="booking.portOfLoadValue && booking.finalDestinationValue">
                  <v-chip pill small v-if="booking.portOfLoadValue">
                    <v-avatar size="14" left>
                      <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                        .substring(0, 2)
                        .toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                    {{ booking.portOfLoadValue }}
                  </v-chip>
                  <v-chip pill small v-if="booking.finalDestinationValue" class="mx-1">
                    <v-avatar size="14" left>
                      <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${booking.finalDestinationValue
                        .substring(0, 2)
                        .toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                    {{ booking.finalDestinationValue }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip>{{ booking.numContainers }}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

     <!-- Move container Dialog -->
     <v-dialog v-model="bookingMoveConfirm" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <!-- <v-toolbar dense flat color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn text @click="bookingMoveConfirm = false">X</v-btn>
                </v-toolbar> -->
        <v-card-text>
          <v-list-item v-if="moveItem">
            <v-list-item-content>
              <v-list-item-title>{{ moveItem.orderNo }}</v-list-item-title>
              <v-list-item-subtitle v-if="moveItem.shipper">
                <v-icon class="mr-1 pb-1" color="grey" small>groups</v-icon>
                {{ moveItem.shipper.name }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="moveItem.consignee">
                <v-icon class="mr-1 pb-1" color="grey" small>call_received</v-icon>
                {{ moveItem.consignee.name }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="moveItem.vessel">
                <v-icon class="mr-1 pb-1" color="grey" small>directions_boat</v-icon>
                {{ moveItem.vessel.name }}
                {{
                  moveItem.mainCarriageConveyanceNumber
                }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="moveItem.portOfLoadValue && moveItem.finalDestinationValue
                  ">
                <v-chip pill small v-if="moveItem.portOfLoadValue">
                  <v-avatar size="14" left>
                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${moveItem.portOfLoadValue
                      .substring(0, 2)
                      .toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                  {{ moveItem.portOfLoadValue }}
                </v-chip>
                <v-chip pill small v-if="moveItem.finalDestinationValue" class="mx-1">
                  <v-avatar size="14" left>
                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${moveItem.finalDestinationValue
                      .substring(0, 2)
                      .toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                  {{ moveItem.finalDestinationValue }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip>{{ moveItem.numContainers }}</v-chip>
            </v-list-item-action>
          </v-list-item>
          <p style="font-size: 16px">
            Are you sure you would like to move
           the selected containers?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="bookingMoveConfirm = false">No</v-btn>
            <v-btn color="success" class="mx-2" text small
              @click="moveMultipleContainer(moveItem)" :loading="loadingCustomerBooking">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
  data: () => ({
    assignToBookingModal: false,
    availableBookings: [],
    bookingTimeout: undefined,
    bookingQuery: undefined,
    bookingMoveConfirm: false,
    customerId: null,
    exceptions: [],
    filteredResults: [],
    filters: null,
    filteredValues: [],
    headers: [
      {
        text: "Action",
        align: "center",
        disableSort: true,
        disableFilter: true,
        value: "actions",
        width: "100px",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "100px",
        filterable: true,
      },
      {
        text: "Container No",
        value: "containerNo",
        align: "center",
        filterable: true,
      },
      {
        text: "Order No",
        value: "orderNo",
        align: "center",
        filterable: true,
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
        align: "center",
        width: "200px",
      },
      {
        text: "Customer",
        value: "customerName",
        width: "220px",
        filterable: true,
      },
      {
        text: "Shipper",
        value: "shipperName",
        width: "220px",
        filterable: true,
      },
      {
        text: "On Behalf Shipper",
        value: "onBehalfShipperName",
        align: "left",
        filterable: true,
      },
      {
        text: "Consignee",
        value: "consigneeName",
        width: "220px",
        filterable: true,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        align: "left",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
        width: "100px",
      },
      {
        text: "ETD",
        value: "etd",
        align: "center",
        width: "100px",
      },
      {
        text: "Dest.",
        value: "finalDestinationValue",
        align: "center",
        width: "100px",
      },
      {
        text: "ETA",
        value: "eta",
        align: "center",
        width: "100px",
      }
    ],
    loading: false,
    loadingCustomerBooking: false,
    loadingOrganisations: false,
    moveItem: {},
    organisations: [],
    search: null,
    searchBooking: null,
    searchHeaders: {},
    searchKey: 200,
    selectAllContainers: false,
    selectedContainerItem: {},
    selectedContainers: [],
    sortBy: null,
    sortDesc: false,
    updateFilterValue: 100,
  }),
  watch: {
    selectAllContainers(val) {
      if (val) {
        this.selectedContainers = this.displayData;
      } else {
        this.selectedContainers = [];
      }
    },
  },
  computed: {
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.63 + "px";
    },
    displayData() {
      let result = this.filteredResults ?? [];
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.regimeCode &&
              x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfLoadCity &&
              x.portOfLoadCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationCity &&
              x.finalDestinationCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shipperReferenceNo &&
              x.shipperReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeReferenceNo &&
              x.consigneeReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerName &&
              x.customerName.toLowerCase().includes(this.search.toLowerCase()))
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    filterAvailableBookings() {
      let result = this.availableBookings ?? [];
      if (this.searchBooking) {
        result = result.filter(
          (x) =>
            x.orderNo
              .toLowerCase()
              .includes(this.searchBooking.toLowerCase()) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.mainCarriageVesselName &&
              x.mainCarriageVesselName
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase()))
        );
      }
      return result;
    },
    tableWidth() {
      let result = 0;
      this.headers.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  mounted() {
    this.getExceptions();
  },
  created() {
    this.getRelations()
    if (localStorage["exceptionFilters_" + this.$store.state.currentOrg.id]) {
      this.filters = JSON.parse(
        localStorage["exceptionFilters_" + this.$store.state.currentOrg.id]
      );
      // console.log("existing filters", this.filters)
      if (typeof this.filters != "object") {
        // this.filters = {}
        this.resetFilters();
      }
      let result = [];
      let keys = Object.keys(this.filters);
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
    }
    if (!this.filters) {
      this.filters = {};
      this.resetFilters();
    }
  },
  methods: {
    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      localStorage.setItem(
        "exceptionFilters_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.filters)
      );
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllContainers = false;
      this.selectedContainers = [];
      this.calculateFilteredResults();
    },
    calculateFilteredResults() {
      let filterKeys = Object.keys(this.filters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.exceptions];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.selectAllContainers = false;
      this.selectedContainers = [];
    },
    filterObject(item, queryText, itemText) {
      return (
        (item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1) ||
          (item.alias && (item.alias.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1))
      );
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      let result = await this.$API.getRelationBasic({
        // params: this.params,
      });
      this.organisations = result.data.filter(x=>x.isCustomer && x.isActive).map(x=>x.relatedOrganisation);
      this.loadingOrganisations = false;
    },
    async getUpcomingBooking() {
      if (this.loadingCustomerBooking) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loadingCustomerBooking && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loadingCustomerBooking = true;
      let params = {
        filter: {  },
      };
      if(this.customerId){
        params.filter = {customerId: this.customerId}
      }
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        let data = await this.$API.getUpcomingBookings({
          params: params,
          signal,
        });
        this.availableBookings = data.data;
        this.loadingCustomerBooking = false;
      }, 750);
    },
    async getExceptions() {
      this.loading = true;
      this.exceptions = await this.$API.getContainerExceptions();
      this.calculateFilteredResults();
      this.loading = false;
    },
    async moveContainerConfirm(item) {
      this.assignToBookingModal = false;
      // this.loadingCustomerBooking = true
      this.moveItem = item;
      console.log(this.moveItem)
      this.bookingMoveConfirm = true;
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.exceptions
            .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    async moveMultipleContainer(item) {
      this.loadingCustomerBooking = true;
        for (let i = 0; i < this.selectedContainers.length; i++) {
          let container = this.selectedContainers[i];
          let obj = {
            ...container,
            status: null,
            bookingId: item.id,
          };
          container.booking = item;
          container.bookingId = item.id;
          await this.$API.updateContainer(obj);
        }
        this.exceptions = this.exceptions.filter(x=>!this.selectedContainers.map(y=>y.id).includes(x.id))
    //   this.getContainerExceptions();
      // let obj = {
      //     ...this.container,
      //     bookingId: item.id,
      // }
      // this.container.booking = item
      // this.container.bookingId = item.id
      // await this.$API.updateContainer(obj)
      this.calculateFilteredResults();
      this.$message.success("Container Successfully Assigned!");
      this.assignToBookingModal = false;
      this.moveItem = null;
      this.bookingMoveConfirm = false;
      this.loadingCustomerBooking = false;
      this.selectedContainers = [];
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.filteredValues = [];
      this.updateFilterValue++;
      this.selectAllContainers = false;
      this.selectedContainers = [];
      localStorage.removeItem(
        "exceptionFilters_" + this.$store.state.currentOrg.id
      );
      this.calculateFilteredResults();
    },
    selectContainer(item) {
      let find = this.selectedContainers.find((x) => x.id == item.id);
      if (!find) {
        this.selectedContainers.push(item);
      } else {
        this.selectedContainers = this.selectedContainers.filter(
          (x) => x.id != item.id
        );
      }
    },
    statusColor(status) {
      switch (status) {
        case "Short Shipped":
          return "red";
      }
    },
    statusIcon(status) {
      switch (status) {
        case "Short Shipped":
          return "warning";
      }
    },
    updateSearchKey() {
      this.searchKey++;
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.booking.systemReference,
      });
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>